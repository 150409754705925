<template>
  <div id="mine" v-if="token">
    <div class="header flex pt50 pl20 pb20">
      <div class="avatar">
        <van-image width="60" height="60" :src="avatar" />
      </div>
      <div class="ml20">{{ userName }}</div>
    </div>
    <van-row>
      <van-col span="6" class="item flex flex-column" @click="goTo('/product')">
        <van-icon name="gift-o"></van-icon>
        <div class="title">商品管理</div>
      </van-col>
      <van-col
        span="6"
        class="item flex flex-column"
        @click="showPurchasePopover = true"
      >
        <van-popover v-model:show="showPurchasePopover">
          <template #reference>
            <div class="item2">
              <van-icon name="gift-o"></van-icon>
              <div class="title">采购管理</div>
            </div>
          </template>
          <router-link
            v-for="(item, index) in purchaseList"
            v-permission="item.permission"
            :key="index"
            :to="{ path: item.path }"
          >
            <van-cell
              :title="item.text"
              @select="onSelect(item)"
              :clickable="true"
            ></van-cell>
          </router-link>
        </van-popover>
      </van-col>
      <van-col
        span="6"
        class="item flex flex-column"
        @click="showRetailPopover = true"
      >
        <van-popover v-model:show="showRetailPopover" @select="onSelect">
          <template #reference>
            <div class="item2">
              <van-icon name="gift-o"></van-icon>
              <div class="title">零售管理</div>
            </div>
          </template>
          <router-link
            v-for="(item, index) in retailList"
            v-permission="item.permission"
            :key="index"
            :to="{ path: item.path }"
          >
            <van-cell
              :title="item.text"
              :clickable="true"
              @select="onSelect(item)"
            ></van-cell>
          </router-link>
        </van-popover>
      </van-col>
      <van-col
        span="6"
        class="item flex flex-column"
        @click="showStockPopover = true"
      >
        <van-popover v-model:show="showStockPopover" @select="onSelect">
          <template #reference>
            <div class="item2">
              <van-icon name="gift-o"></van-icon>
              <div class="title">库存管理</div>
            </div>
          </template>
          <router-link
            v-for="(item, index) in stockList"
            v-permission="item.permission"
            :key="index"
            :to="{ path: item.path }"
          >
            <van-cell
              :title="item.text"
              :clickable="true"
              @select="onSelect(item)"
            ></van-cell>
          </router-link>
        </van-popover>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="6" class="item flex flex-column" @click="toLogout">
        <van-icon name="share-o"></van-icon>
        <div class="title">退出登录</div>
      </van-col>
    </van-row>
    <van-cell-group> </van-cell-group>
    <!--头像及其他信息-->

    <!-- <van-cell-group style="margin-top: 0.6rem">
      <van-cell title="联系客服" value="客服时间 07:00-22:00" is-link icon="phone"/>
      <van-cell title="意见反馈" is-link icon="smile-comment"/>
    </van-cell-group> -->
    <!--路由出口-->
    <transition name="router-slider" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
  <SelectLogin v-else />
</template>

<script>
import { logout } from "../../service/api/product";
import SelectLogin from "../login/SelectLogin";
import avatar from "@/images/avatar.jpeg";
import { Dialog } from "vant";
export default {
  name: "Mine",
  data() {
    return {
      avatar: avatar,
      token: true,
      userInfo: { user: { name: "", phone: "" } },
      orderData: [
        { title: "待支付", icon: "cart-circle-o" },
        { title: "待收货", icon: "gift-o" },
        { title: "待评价", icon: "smile-comment-o" },
        { title: "售后/退款", icon: "cash-back-record" },
      ],
      userName: "",
      phone: "",
      avatar: "",
      showPurchasePopover: false,
      showRetailPopover: false,
      showStockPopover: false,
      purchaseList: [
        {
          text: "采购参数设置",
          icon: "",
          path: "/purchase/config",
          permission: "purchase:config:modify",
        },
        {
          text: "采购订单管理",
          icon: "",
          path: "/purchase/order",
          permission: "purchase:order:query",
        },
        {
          text: "采购收货管理",
          icon: "",
          path: "/purchase/receive",
          permission: "purchase:receive:query",
        },
        {
          text: "采购退货管理",
          icon: "",
          path: "/purchase/return",
          permission: "purchase:return:query",
        },
      ],
      retailList: [
        {
          text: "零售参数设置",
          icon: "",
          path: "/retail/config",
          permission: "retail:config:modify",
        },
        {
          text: "零售出库管理",
          icon: "",
          path: "/retail/out",
          permission: "retail:out:query",
        },
        {
          text: "零售退货管理",
          icon: "",
          path: "/retail/return",
          permission: "retail:return:query",
        },
      ],
      stockList: [
        {
          text: "商品库存",
          icon: "",
          path: "/stock/product",
          permission: "stock:product:query",
        },
        {
          text: "库存变动记录",
          icon: "",
          path: "/stock/product/log",
          permission: "stock:product-log:query",
        },
        // { text: "仓库调拨", icon: "", path: "/stock/transfer" ,permission:'stock:sc-transfer:query'},
        {
          text: "商品批次",
          icon: "",
          path: "/stock/lot",
          permission: "stock:lot:query",
        },
      ],
    };
  },
  created() {
    this.roken = localStorage.getItem("token");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userName = this.userInfo.merchName || this.userInfo?.user?.name;
    this.phone = this.userInfo?.merchPhone;
    this.avatar = this.userName ? this.userName.substring(0, 1) : "商";
    console.log(this.userInfo);
  },
  components: {
    SelectLogin,
  },
  methods: {
    onSelect(action) {
      console.log(action);
      this.goTo(action.path);
    },
    toLogout() {
      Dialog.confirm({
        title: "退出登录",
        message: "确认退出登录？",
      })
        .then(async () => {
          await logout();
          localStorage.clear();
          sessionStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.$router.push("/login");
        });
    },
    goTo(target) {
      this.$router.push(target);
    },
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  background-color: #f5f5f5;
}
.header {
  background-image: linear-gradient(135deg, #f5918b, #f38a62 69%, #ff7979);
  color: #fff;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  font-size: 22px;
  color: #fff;
  line-height: 60px;
}
.van-nav-bar {
  background-color: #3bba63;
}

#mine /deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 1rem;
}

.personMsg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.personMsg > img {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  border: 0.12rem solid #fff;
}

.personInfo {
  display: flex;
  flex-direction: column;
  margin-left: 0.8rem;
}

#mine /deep/ .van-cell__left-icon {
  color: orange;
  font-size: 1.2rem;
}

.router-slider-enter-active,
.router-slider-leave-active {
  transition: all 0.3s;
}

.router-slider-enter,
.router-slider-leave-active {
  transform: translate3d(2rem, 0, 0);
  opacity: 0;
}
.van-row {
  background: #fff;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
}
.item {
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #333;
}
.item2 {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #333;
}
.title {
  padding: 5px 0;
}
.van-icon {
  color: red;
  font-size: 20px;
}
</style>
